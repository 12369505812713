@import '~antd/dist/antd.less';
@import '~rc-footer/assets/index.css';
@import './theme.less';

/* External fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap'); // Poppins

body {
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 300;
}

.error-boundary {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.app {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    min-height: 100vh !important;
    overflow-y: auto !important;

    &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        background-image:  radial-gradient(@primary-color 0.75px, transparent 0.75px), radial-gradient(@primary-color 0.75px, #ffffff 0.75px);
        background-size: 50px 50px;
        background-position: 0 0, 25px 25px;
        opacity: 0.2;
        z-index: 0;
    }

    > .app-header {
        position: relative !important;
        flex-shrink: 1;
        background-color: white !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        z-index: 1;
    }

    > .app-content {
        position: relative !important;
        display: flex;
        flex-direction: row;
        flex-grow: 1 !important;
        background-color: transparent !important;
        z-index: 1;
    }

    > .app-footer {
        position: relative !important;
        flex-shrink: 1;
        padding: 0 !important;
        z-index: 1;
    }
}

.loading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &[data-loading="true"] {
        .loading-loader {
            opacity: 1;
        }

        .loading-content {
            opacity: 0;
        }
    }

    &[data-loading="false"] {
        .loading-content {
            transition: opacity 0.5s ease-in-out;
        }
    }

    .loading-loader {
        position: absolute;
        opacity: 0;
    }

    .loading-content {
        opacity: 1;
    }
}

/* Helper classes */

.container() {
    margin: 0 auto;
    width: 100%;
}
.container-xs {
    .container();
    max-width: @screen-xs;
}
.container-sm {
    .container();
    max-width: @screen-sm;
}
.container, .container-md {
    .container();
    max-width: @screen-md;
}
.container-lg {
    .container();
    max-width: @screen-lg;
}
.container-xl {
    .container();
    max-width: @screen-xl;
}
.container-xxl {
    .container();
    max-width: @screen-xxl;
}

.text-left { text-align: left !important; }
.text-center { text-align: center !important; }
.text-right { text-align: right !important; }
.text-justify { text-align: justify !important; }

@media (max-width: @screen-xs) {
    .text-left-xs { text-align: left !important; }
    .text-center-xs { text-align: center !important; }
    .text-right-xs { text-align: right !important; }
    .text-justify-xs { text-align: justify !important }
}

@media (max-width: @screen-sm) {
    .text-left-sm { text-align: left !important; }
    .text-center-sm { text-align: center !important; }
    .text-right-sm { text-align: right !important; }
    .text-justify-sm { text-align: justify !important }
}

@media (max-width: @screen-md) {
    .text-left-md { text-align: left !important; }
    .text-center-md { text-align: center !important; }
    .text-right-md { text-align: right !important; }
    .text-justify-md { text-align: justify !important }
}

.bg-primary { background: @primary-color !important; }
.bg-secondary { background: @secondary-color !important; }
.bg-accent { background: @accent-color !important; }
.bg-light { background: @light-color !important; }
.bg-dark { background: @dark-color !important; }
.bg-white { background: white !important; }
.bg-black { background: black !important; }

.c-primary { color: @primary-color !important; }
.c-secondary { color: @secondary-color !important; }
.c-accent { color: @accent-color !important; }
.c-success { color: @green-6 !important; }
.c-warning { color: @gold-6 !important; }
.c-error { color: @red-5 !important; }
.c-light { color: @light-color !important; }
.c-dark { color: @dark-color !important; }
.c-white { color: #ffffff !important; }
.c-black { color: #000000 !important; }

.font-size-xxs { font-size: 0.675rem !important; }
.font-size-xs { font-size: 0.75rem !important; }
.font-size-sm { font-size: 0.875rem !important; }
.font-size-md { font-size: 1rem !important; }
.font-size-lg { font-size: 1.125rem !important; }
.font-size-xl { font-size: 1.25rem !important; }
.font-size-xxl { font-size: 1.5rem !important; }
.font-size-xxxl { font-size: 2rem !important; }

@fontWeights: 100, 200, 300, 400, 500, 600, 700, 800, 900;
each(@fontWeights, {
    .font-weight-@{value} { font-weight: @value !important; }
});

.lh-unset { line-height: unset !important; }
.lh-1 { line-height: 1 !important; }
.lh-1_25 { line-height: 1.25 !important; }
.lh-1_5 { line-height: 1.5 !important; }

.b-0 { border: none !important; }
.bl-0 { border-left: none !important; }
.br-0 { border-right: none !important; }
.bt-0 { border-top: none !important; }
.bb-0 { border-bottom: none !important; }
each(range(1, 5), {
    .b-grey-@{value} { border: (@value * 1px) solid @grey-color !important; }
    .bl-grey-@{value} { border-left: (@value * 1px) solid @grey-color !important; }
    .br-grey-@{value} { border-right: (@value * 1px) solid @grey-color !important; }
    .bt-grey-@{value} { border-top: (@value * 1px) solid @grey-color !important; }
    .bb-grey-@{value} { border-bottom: (@value * 1px) solid @grey-color !important; }
    .bs-grey-@{value} { box-shadow: 0 0 (@value * 5px) (@value * 1px) @grey-color !important; }
    .bs-inset-grey-@{value} { box-shadow: inset 0 0 (@value * 5px) (@value * 1px) @grey-color !important; }

    .b-lightgrey-@{value} { border: (@value * 1px) solid @lightgrey-color !important; }
    .bl-lightgrey-@{value} { border-left: (@value * 1px) solid @lightgrey-color !important; }
    .br-lightgrey-@{value} { border-right: (@value * 1px) solid @lightgrey-color !important; }
    .bt-lightgrey-@{value} { border-top: (@value * 1px) solid @lightgrey-color !important; }
    .bb-lightgrey-@{value} { border-bottom: (@value * 1px) solid @lightgrey-color !important; }
    .bs-lightgrey-@{value} { box-shadow: 0 0 (@value * 5px) (@value * 1px) @lightgrey-color !important; }
    .bs-inset-lightgrey-@{value} { box-shadow: inset 0 0 (@value * 5px) (@value * 1px) @lightgrey-color !important; }
});

@iterations: 0, 1, 2, 3, 4, 5, 10, 25, 50, 100;
each(@iterations, {
    .br-@{value} {
        border-radius: @value * 1px !important;
        > .ant-select-selector { border-radius: @value * 1px !important; }
    }
    .btlr-@{value} { border-top-left-radius: @value * 1px !important; }
    .btrr-@{value} { border-top-right-radius: @value * 1px !important; }
    .bblr-@{value} { border-bottom-left-radius: @value * 1px !important; }
    .bbrr-@{value} { border-bottom-right-radius: @value * 1px !important; }
    .bw-@{value} { border-width: @value * 1px !important; }
    .m-@{value} { margin: (@value * 1px) !important; }
    .mx-@{value} { margin-left: (@value * 1px) !important; margin-right: (@value * 1px) !important; }
    .ml-@{value} { margin-left: (@value * 1px) !important; }
    .mr-@{value} { margin-right: (@value * 1px) !important; }
    .my-@{value} { margin-top: (@value * 1px) !important; margin-bottom: (@value * 1px) !important; }
    .mt-@{value} { margin-top: (@value * 1px) !important; }
    .mb-@{value} { margin-bottom: (@value * 1px) !important; }
    .p-@{value} { padding: (@value * 1px) !important; }
    .px-@{value} { padding-left: (@value * 1px) !important; padding-right: (@value * 1px) !important; }
    .pl-@{value} { padding-left: (@value * 1px) !important; }
    .pr-@{value} { padding-right: (@value * 1px) !important; }
    .py-@{value} { padding-top: (@value * 1px) !important; padding-bottom: (@value * 1px) !important; }
    .pt-@{value} { padding-top: (@value * 1px) !important; }
    .pb-@{value} { padding-bottom: (@value * 1px) !important; }
});

@media (max-width: @screen-sm) {
    .m-50 { margin: 25px !important; }
    .mx-50 { margin-left: 25px !important; margin-right: 25px !important; }
    .ml-50 { margin-left: 25px !important; }
    .mr-50 { margin-right: 25px !important; }
    .my-50 { margin-top: 25px !important; margin-bottom: 25px !important; }
    .mt-50 { margin-top: 25px !important; }
    .mb-50 { margin-bottom: 25px !important; }

    .m-100 { margin: 50px !important; }
    .mx-100 { margin-left: 50px !important; margin-right: 50px !important; }
    .ml-100 { margin-left: 50px !important; }
    .mr-100 { margin-right: 50px !important; }
    .my-100 { margin-top: 50px !important; margin-bottom: 50px !important; }
    .mt-100 { margin-top: 50px !important; }
    .mb-100 { margin-bottom: 50px !important; }

    .p-50 { padding: 25px !important; }
    .px-50 { padding-left: 25px !important; padding-right: 25px !important; }
    .pl-50 { padding-left: 25px !important; }
    .pr-50 { padding-right: 25px !important; }
    .py-50 { padding-top: 25px !important; padding-bottom: 25px !important; }
    .pt-50 { padding-top: 25px !important; }
    .pb-50 { padding-bottom: 25px !important; }

    .p-100 { padding: 50px !important; }
    .px-100 { padding-left: 50px !important; padding-right: 50px !important; }
    .pl-100 { padding-left: 50px !important; }
    .pr-100 { padding-right: 50px !important; }
    .py-100 { padding-top: 50px !important; padding-bottom: 50px !important; }
    .pt-100 { padding-top: 50px !important; }
    .pb-100 { padding-bottom: 50px !important; }
}

.w-100 { width: 100% !important; }
.h-100 { height: 100% !important; }

.flex { display: flex !important; }
.flex-direction-column { flex-direction: column !important; }
.flex-direction-row { flex-direction: row !important; }
.flex-align-center { align-items: center !important; }
.flex-justify-center { justify-content: center !important; }
.flex-grow-1 { flex-grow: 1 !important; }
.flex-shrink-1 { flex-shrink: 1 !important; }

.vertical-align-middle { vertical-align: middle !important; }

.overflow-hidden { overflow: hidden !important; }

.z-index-n1 { z-index: -1 !important; }
.z-index-0 { z-index: 0 !important; }
.z-index-1 { z-index: 1 !important; }

.ant-card-head-title .ant-page-header-heading-title { font-weight: 300; }
.ant-drawer-left .ant-drawer-content { border-top-right-radius: 10px; border-bottom-right-radius: 10px; }
.ant-menu-submenu-popup > .ant-menu-sub { border-radius: 10px; }
.ant-modal-content { border-radius: 10px; }
.ant-modal-header { border-radius: 10px 10px 0 0; }
.ant-popover-buttons > .ant-btn { border-radius: 5px; }
.ant-popover-inner { border-radius: 10px; }
