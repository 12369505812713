.paymentMethods {
    margin-top: 25px !important;
    [class*="ant-list-items"] {
        margin-top: -10px;
        margin-bottom: -10px;

        > [class*="ant-list-item"] {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}
