.notification {
    .notificationContent {
        margin-bottom: 5px !important;
        opacity: 1;
        transition: opacity 0.25s ease-in-out;

        [class*="ant-list-item-meta-title"] {
            margin-bottom: 0 !important;
        }

        [class*="ant-list-item-meta-description"] {
            font-size: 0.75rem !important;
        }
    }

    &[data-read="true"] {
        .notificationContent {
            opacity: 0.25;
        }
    }
}
