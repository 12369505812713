.navbar {
    border-bottom: 0 !important;

    .navbarItem {
        user-select: none !important;

        &::after { border-bottom: none !important; }

        &.navbarItemNoHover {
            color: inherit !important;
            cursor: default !important;
        }

        &.navbarItemDivider {
            flex-grow: 1 !important;
        }

        > [class="ant-menu-title-content"] {
            > .navbarItemLoginSkeleton {
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
    }
}
