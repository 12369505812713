@import '~antd/lib/style/themes/default';
@import '../../theme.less';

@homeBannerContentImagesIterations: 1, 2, 3;

.home {
    position: relative;
    width: 100%;

    > .homeBanner {
        position: relative;
        width: 100%;
        min-height: 400px;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(45deg, @secondary-color, @accent-color);
            transform: skewY(-3deg);
            z-index: -1;

            @media (max-width: @screen-md) {
                transform: skewY(0deg);
            }
        }

        .homeBannerContent {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            width: auto;
            min-height: inherit;
            margin: 0 auto;
            padding: 50px;
            z-index: 1;

            .homeBannerContentText {
                padding: 20px;

                > * {
                    color: #ffffff;
                    font-weight: 400;
                }
            }

            @media (max-width: @screen-xs) {
                flex-wrap: wrap;
                max-width: unset;
                padding: 20px;

                .homeBannerContentText {
                    text-align: center;
                }
            }

            @media (min-width: (@screen-xs + 1px)) and (max-width: @screen-sm) {
                flex-wrap: wrap;
                max-width: @screen-xs;
                padding: 20px;

                .homeBannerContentText {
                    text-align: center;
                }
            }

            @media (min-width: (@screen-sm + 1px)) and (max-width: @screen-md) {
                flex-wrap: wrap;
                max-width: @screen-sm;
                padding: 20px;
            }

            @media (min-width: (@screen-md + 1px)) and (max-width: @screen-lg) {
                max-width: @screen-md;
                padding: 50px 20px;
            }

            @media (min-width: (@screen-lg + 1px)) {
                max-width: @screen-lg;
                padding: 50px 20px;
            }
        }

        .homeBannerImages {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: inherit;
            width: 100%;
            transform: skewY(-3deg);
            z-index: 0;

            @media (max-width: @screen-md) {
                transform: skewY(0deg);
            }

            > img {
                height: 100%;
                //min-width: 400px;
                opacity: 0.1;

                + img {
                    border-left: 5px solid white;
                }
            }
        }
    }
}
